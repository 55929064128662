import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import i18n from './i18n';
import Axios from 'axios';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import vuetify from './plugins/vuetify';
import config from '@/config.json';

import '@/css/app.scss';

const { VUE_APP_API_URL, VUE_APP_SENTRY_DSN } = config;

export const eventBus = new Vue();
Sentry.init({
    dsn: VUE_APP_SENTRY_DSN,
    integrations: [
        new VueIntegration({
            Vue,
            tracing: true,
            tracingOptions: {
                trackComponents: true
            }
        }),
        new Integrations.BrowserTracing({ tracingOrigins: [VUE_APP_API_URL] })
    ],
    tracesSampleRate: 1
});

Vue.use(VueLodash, { name: 'custom', lodash: lodash });

new Vue({
    el: '#app',
    router,
    store,
    vuetify,
    i18n,
    beforeCreate() {
        Axios.defaults.baseURL = VUE_APP_API_URL;
        Axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                if (
                    error.response.hasOwnProperty('data') &&
                    error.response.data
                ) {
                    let message = '';
                    for (let key in error.response.data) {
                        if (error.response.data.hasOwnProperty(key)) {
                            if (key !== 'trace') {
                                message +=
                                    key +
                                    ': ' +
                                    error.response.data[key] +
                                    '\n';
                            }
                        }
                    }
                    Sentry.addBreadcrumb({
                        level: Sentry.Severity.Info,
                        category: 'xhrResponse',
                        message
                    });
                }
                Sentry.captureException(error);
                return Promise.reject(error);
            }
        );
    },
    render: (h) => h(App)
});
