import Axios from 'axios';
import i18n from '../../i18n';
import vuetify from '../../plugins/vuetify';
import { generateGetUrl } from '../../base';
import config from '@/config.json';

const { VUE_APP_API_URL, VUE_APP_APP_URL } = config;

export default {
    state: {
        apiUrl: VUE_APP_API_URL,
        appUrl: VUE_APP_APP_URL,
        placeUuid: null,
        appIsLoading: true,
        token: null,
        locale: 'ua',
        place: {
            name: null,
            description: null,
            mainImage: null,
            deliveryTypes: []
        },
        demo: false,
        //use only for demo preview
        appType: 'qr',
        allowGoBackButton: false
    },
    actions: {
        getToken(ctx, data) {
            let url = `/v1/app/get_token/${data.uuid}`;
            const getUrl = generateGetUrl(url, data.params);
            return Axios.get(getUrl).then((response) => {
                if (!data.demo) {
                    ctx.commit('setToken', {
                        token: response.data.data.token
                    });
                } else {
                    ctx.commit('setDemoToken', {
                        token: response.data.data.token
                    });
                }
            });
        },
        getPlace(ctx) {
            return Axios.get('/v1/app/place').then((response) => {
                const data = response.data.data;
                ctx.commit('setPlace', {
                    name: data.scenario.name_for_guests,
                    description: data.scenario.description_for_guests,
                    mainImage: data.scenario.background_img,
                    deliveryTypes: data.scenario.menu_order_types
                });
                if (data.location.locale) {
                    ctx.commit('setLocale', data.location.locale);
                }
                if (data.scenario.social_active && data.location.social) {
                    ctx.commit('setSocial', data.location.social);
                }
                if (
                    data.scenario.hasOwnProperty('menu_active') &&
                    data.scenario.menu_active &&
                    data.scenario.hasOwnProperty('menu')
                ) {
                    ctx.commit('setMenu', data.scenario.menu);
                    ctx.commit('setOrderFromLocalStorage');
                    ctx.commit('setIsCartEnable', !!data.scenario.menu_cart);
                }
                if (
                    data.scenario.hasOwnProperty('call_buttons_active') &&
                    data.scenario.call_buttons_active &&
                    data.scenario.hasOwnProperty('call_buttons')
                ) {
                    ctx.commit('setCallButtons', data.scenario.call_buttons);
                }
                if (
                    data.scenario.hasOwnProperty('review_active') &&
                    data.scenario.review_active &&
                    data.scenario.hasOwnProperty('review')
                ) {
                    ctx.commit('setReview', data.scenario.review);
                }
                if (
                    data.scenario.hasOwnProperty('custom_buttons_active') &&
                    data.scenario.custom_buttons_active &&
                    data.scenario.hasOwnProperty('custom_buttons')
                ) {
                    ctx.commit(
                        'setCustomButtons',
                        data.scenario.custom_buttons
                    );
                }
                if (data.scenario.hasOwnProperty('theme_styles')) {
                    if (data.scenario.theme_styles.color) {
                        vuetify.framework.theme.themes.light.primary =
                            data.scenario.theme_styles.color;
                    }
                }
            });
        }
    },
    mutations: {
        setPlaceUuid(state, payload) {
            state.placeUuid = payload;
        },
        setToken(state, payload) {
            state.token = payload.token;
            Axios.defaults.headers.common.Token = payload.token;
            window.localStorage.setItem('token', payload.token);
        },
        setLocale(state, payload) {
            state.locale = payload;
            i18n.locale = payload;
        },
        setDemoToken(state, payload) {
            state.token = payload.token;
            Axios.defaults.headers.common.Token = payload.token;
        },
        setPlace(state, payload) {
            state.place.name = payload.name;
            state.place.description = payload.description;
            state.place.mainImage = payload.mainImage;
            state.place.deliveryTypes = payload.deliveryTypes;
        },
        setAppIsLoading(state, payload) {
            state.appIsLoading = payload;
        },
        setDemo(state, payload) {
            state.demo = payload;
        },
        setAppType(state, payload) {
            state.appType = payload;
        },
        setAllowGoBackButton(state, payload) {
            state.allowGoBackButton = payload;
        }
    },
    getters: {
        apiUrl: (state) => state.apiUrl,
        appUrl: (state) => state.appUrl,
        placeUuid: (state) => state.placeUuid,
        appIsLoading: (state) => state.appIsLoading,
        place: (state) => state.place,
        placeName: (state) => state.place.name,
        demo: (state) => state.demo,
        appType: (state) => state.appType,
        allowGoBackButton: (state) => state.allowGoBackButton
    }
};
