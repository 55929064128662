<template>
    <v-card class="incorrectUrlAlert d-flex align-center justify-center" flat>
        <div>
            <p class="title mb-1">
                {{ error.title }}
            </p>
            <p class="sub-title mb-9">
                {{ error.text }}
            </p>
            <v-img
                aspect-ratio="1.15"
                class="image"
                :src="require('@/assets/images/desktop-alert/desktop-alert.svg')"
            />
        </div>
    </v-card>
</template>
<script>
export default {
    props: {
        errorType: {
            type: String,
            required: true
        }
    },
    computed: {
        error() {
            let result = {};
            switch (this.errorType) {
                case 'invalid_qr':
                    result.title = this.$t('invalid_qr_title');
                    result.text = this.$t('invalid_qr_text');
                    break;
                case 'token_expired':
                    result.title = this.$t('token_expired_title');
                    result.text = this.$t('token_expired_text');
                    break;
                case 'forbidden':
                    result.title = this.$t('forbidden_title');
                    result.text = this.$t('forbidden_text');
                    break;
                case 'service_not_working':
                    result.title = this.$t('service_not_working_title');
                    result.text = this.$t('service_not_working_text');
                    break;
                case 'service_not_configure':
                    result.title = this.$t('service_not_configure_title');
                    result.text = this.$t('service_not_configure_text');
                    break;
                case 'invalid_web':
                    result.title = this.$t('invalid_web_title');
                    result.text = this.$t('invalid_web_text');
                    break;
                case 'token_web_expired':
                    result.title = this.$t('token_web_expired_title');
                    result.text = this.$t('token_web_expired_text');
                    break;
                default:
                    result.title = this.$t('service_not_working_title');
                    result.text = this.$t('service_not_working_text');
                    break;
            }
            return result;
        }
    }
};
</script>
<style lang="scss" scoped>
.incorrectUrlAlert {
    box-sizing: border-box;
    height: 100%;
    padding: 50px 30px 60px;
    text-align: center;

    .image {
        display: block;
        margin: 0 auto;
        width: 80%;
        max-width: 400px;
    }
}
</style>
