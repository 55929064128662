import Axios from 'axios';

export default {
    state: {
        callButtons: null
    },
    actions: {
        postCallButton(ctx, data) {
            return Axios.post('/api/application/call_button', {
                place_uuid: data.placeUuid,
                button_uuid: data.buttonUuid
            });
        }
    },
    mutations: {
        setCallButtons(state, payload) {
            state.callButtons = payload;
        }
    },
    getters: {
        callButtons: (state) => state.callButtons
    }
};
