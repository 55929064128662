export default {
    state: {
        menu: {
            type: null,
            url: null,
            fileUrl: null,
            currencyCode: null,
            positionMap: [],
            parentCategories: [],
            categories: [],
            positions: []
        },
        selectedProduct: null,
        isSelectedProductVisible: false
    },
    actions: {},
    mutations: {
        setMenu: (state, payload) => {
            if (payload.type === 'INTERACTIVE' || payload.type === 'POSTER') {
                let positionMap = payload.position_map;
                let recursive = (node, deep = 1) => {
                    for (let i = 0; i < node.length; i += 1) {
                        let object = null;
                        if (deep === 1) {
                            object = _.find(
                                payload.categories,
                                (category) => category.uuid === node[i].id
                            );
                        } else if (deep === 2) {
                            object =
                                _.find(
                                    payload.categories,
                                    (category) => category.uuid === node[i].id
                                ) ||
                                _.find(
                                    payload.items,
                                    (item) => item.uuid === node[i].id
                                );
                        } else if (deep === 3) {
                            object = _.find(
                                payload.items,
                                (item) => item.uuid === node[i].id
                            );
                        }

                        if (object) {
                            if (object.active) {
                                node[i].element = object;
                                if (object.hasOwnProperty('parent')) {
                                    node[i].parent = object.parent;
                                    if (
                                        node[i].hasOwnProperty('children') &&
                                        node[i].children.length
                                    ) {
                                        recursive(node[i].children, deep + 1);
                                    } else {
                                        node.splice(i, 1);
                                        i--;
                                    }
                                }
                            } else {
                                node.splice(i, 1);
                                i--;
                            }
                        }
                    }
                };
                recursive(positionMap);

                state.menu.positionMap = positionMap;
                state.menu.positions = payload.items;
                state.menu.categories = payload.categories;
                state.menu.currencyCode = payload.currency.code;
            }

            state.menu.type = payload.type;
            state.menu.url = payload.url;
            state.menu.fileUrl = payload.file_url;
        },
        setSelectedProduct: (state, payload) => {
            state.selectedProduct = { ...payload, ...{ modification: null } };
        },
        setSelectedProductProperty: (state, payload) => {
            if (state.selectedProduct.hasOwnProperty(payload.key)) {
                state.selectedProduct[payload.key] = payload.value;
            }
        },
        setIsSelectedProductVisible: (state, payload) =>
            (state.isSelectedProductVisible = payload)
    },
    getters: {
        menu: (state) => state.menu,
        positionMap: (state) => state.menu.positionMap,
        categories: (state) => state.menu.categories,
        categoryByUuid: (state) => (uuid) =>
            _.find(state.menu.categories, (category) => category.uuid === uuid),
        menuItems: (state) => (uuid) => {
            const category = _.find(
                state.menu.categories,
                (category) => category.uuid === uuid
            );
            let result;
            if (category) {
                result = category.items ? category.items : [];
            } else {
                result = [];
            }
            return result;
        },
        selectedProduct: (state) => state.selectedProduct,
        isSelectedProductVisible: (state) => state.isSelectedProductVisible,
        currencyCode: (state) => state.menu.currencyCode
    }
};
