export const ua = {
    /*app*/
    sent: 'Відгук відправлено',
    required_field: "Обов'язкове поле",
    input_max_length: 'Перевищена к-сть символів',
    incorrect_format_photo: 'Невірний формат фото',
    next: 'Далі',
    phone_number: 'Ваш номер телефону',
    your_name: "Ваше ім'я",
    invalid_phone: 'Невірний номер телефону',
    close: 'Закрити',
    cancel: 'Відмінити',
    yes: 'Так',
    no: 'Ні',
    minutes: 'хв',

    /*errors*/
    invalid_qr_title: 'QR-код не дійсний',
    invalid_qr_text: 'Спробуйте повторно відсканувати QR-код',
    invalid_web_title: 'Посилання не дійсне',
    invalid_web_text: '',
    token_expired_title: 'Час дії сесії вичерпано',
    token_expired_text: 'Відскануйте QR-код',
    token_web_expired_title: 'Час дії сесії вичерпано',
    token_web_expired_text: '',
    forbidden_title: 'Доступ обмежений',
    forbidden_text: 'Будь ласка, спробуйте пізніше',
    service_not_working_title: 'Сервіс тимчасово недоступний',
    service_not_working_text: 'Будь ласка, спробуйте пізніше',
    oops: 'Упс!',
    mobile_only_alert:
        'Нажаль, ця сторінка працює лише на мобільних та планшетних пристроях',
    network_error: 'Помилка мережі. Будь ласка, спробуйте ще раз',
    use_max_characters: 'Використовуйте до {number} символів',
    service_not_configure_title: 'Сервіс не налаштований',
    service_not_configure_text: 'Будь ласка, спробуйте пізніше',

    /*home*/
    home_title: 'Оберіть дію',
    home_menu: 'Переглянути меню',
    home_call_buttons: 'Кнопки виклику',
    home_review: 'Залишити відгук',
    your_menu_will_be_here: 'Тут буде ваше меню :)',

    /*call_buttons*/
    call_buttons_title: 'Оберіть необхідну дію',
    call_buttons_success_popup_title: 'Дякуємо!',
    call_buttons_success_popup_text: 'Ми отримали ваше побажання ☺',

    /*review_module*/
    review_title: 'Які ваші враження?',
    review_your_rating: 'Ваша оцінка*',
    review_rating_1: 'Дуже погано 😡',
    review_rating_2: 'Погано 😠',
    review_rating_3: 'Середньо 😐',
    review_rating_4: 'Добре 🙂',
    review_rating_5: 'Чудово 🤩',
    review_message_label: 'Коментар*',
    review_send_button: 'Відправити',
    review_photo_popup_title: 'Фото до відгуку',
    review_photo_popup_subtitle: 'Ви можете додати фото до вашого відгуку',
    review_photo_popup_add_photo_button: 'Додати фото',
    review_success_title: 'Дякуємо 🙂',
    review_success_text: 'Ми отримали ваш відгук',
    review_success_new_review_button: 'Залишити новий відгук',
    review_contact_info_title: 'Дякуємо',
    review_contact_info_text:
        'Будь ласка, залиште свій номер телефону, щоб ми могли зв’язатися з вами у разі потреби',
    review_change_review_button: 'Змінити відгук',

    //menu
    add_to_cart: 'Додати до замовлення',
    go_to_cart: 'До замовлення',
    go_to_menu: 'Повернутися до меню',
    position_was_added_to_cart: 'Позиція додана до замовлення',
    position_was_remove_from_cart: 'Позиція видалена з замовлення',
    from_price: 'від',
    please_choose_modification: 'Будь ласка, оберіть товар зі списку',

    //currency
    USD: 'дол',
    EUR: 'евро',
    UAH: 'грн',
    RUB: 'руб',

    //cart
    cart_title: 'Оформлення замовлення',
    cart_title_static: 'Список збережених позицій',
    cart_text_static: 'Покличте офіціанта, якщо готові зробити замовлення',
    cart_order_label: 'Ваше замовлення',
    cart_total_text: 'Всього до сплати',
    cart_delivery_label: 'Тип замовлення',
    cart_payment_label: 'Варіанти оплати',
    cart_comment_label: 'Коментарій до замовлення',
    cart_checkout_button: 'Оформити замовлення',
    cart_delete_title: 'Видалення позиції',
    cart_delete_text: 'Бажаєте видалити цю позицію з замовлення?',
    approve: 'Підтвердити',
    IN_HOUSE: 'В закладі',
    TAKE_AWAY: 'З собою',
    CASH: 'Оплата готівкою',
    CARD: 'Оплата карткою',
    cart_success_title: 'Дякуємо 🙂',
    cart_success_text: 'Ми отримали ваше замовлення',
    cart_back_button: 'До головної сторінки',
    cart_clear_order: 'Очистити список',
    order_successfully_clear: 'Ваш список очіщено'
};
