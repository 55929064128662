import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#4a8edb',
                facebook: '#3b5998',
                instagram: '#AC348D',
                tripadvisor: '#00af87'
            },
            dark: {}
        }
    }
};

export default new Vuetify(opts);
