import Vue from 'vue';
import VueRouter from 'vue-router';
/*import Home from '../views/Home';
import Menu from '../views/menu';
import Categories from '../views/menu/Categories';
import Category from '../views/menu/Category';
import Positions from '../views/menu/Positions';
import CallButtons from '../views/CallButtons';
import Review from '../views/review';*/

Vue.use(VueRouter);

const Main = () => import('../views/Main');
const Home = () => import('../views/Home');
const Menu = () => import('../views/menu');
const Categories = () => import('../views/menu/Categories');
const Category = () => import('../views/menu/Category');
const Positions = () => import('../views/menu/Positions');
const CallButtons = () => import('../views/CallButtons');
const Review = () => import('../views/review');

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

export const routes = [
    {
        name: 'Main',
        path: '*',
        component: Main,
        /*redirect: '/home',*/
        children: [
            {
                name: 'Home',
                path: '/home',
                component: Home
            },
            {
                name: 'Menu',
                path: '/menu',
                component: Menu,
                redirect: '/menu/categories',
                children: [
                    {
                        name: 'Categories',
                        path: '/menu/categories',
                        component: Categories
                    },
                    {
                        name: 'Category',
                        path: '/menu/category/:uuid',
                        component: Category
                    },
                    {
                        name: 'Positions',
                        path: '/menu/category/:uuid/positions',
                        component: Positions
                    }
                ]
            },
            {
                name: 'CallButtons',
                path: '/call-buttons',
                component: CallButtons
            },
            {
                name: 'Review',
                path: '/review',
                component: Review
            }
        ]
    }
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

export default router;
