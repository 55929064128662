import Axios from 'axios';
import { setOrderLocalStorage } from '../../base';

export default {
    state: {
        isCartEnable: false,
        isCartVisible: false,
        cartState: 'default',
        order: {
            cartItems: [],
            deliveryType: null,
            paymentType: null,
            message: ''
        }
    },
    actions: {
        sendOrder(ctx, data) {
            let items = _.map(data.cartItems, (item) => {
                let result = {
                    uuid: item.uuid,
                    qty: item.qty
                };
                if (item.modificationUuid) {
                    result.modification_uuid = item.modificationUuid;
                }
                return result;
            });
            return Axios.post('/v1/app/menu_orders', {
                items,
                delivery_type: data.deliveryType,
                payment_type: data.paymentType,
                note: data.message
            });
        }
    },
    mutations: {
        setIsCartEnable: (state, payload) => (state.isCartEnable = payload),
        setIsCartVisible: (state, payload) => (state.isCartVisible = payload),
        setCartState: (state, payload) => (state.cartState = payload),
        clearOrder: (state) => {
            state.order.cartItems = [];
            state.order.deliveryType = null;
            state.order.paymentType = null;
            state.order.message = '';
            localStorage.removeItem('order');
        },
        setOrderFromLocalStorage: (state) => {
            const orderLocalStorage = localStorage.getItem('order');
            if (orderLocalStorage) {
                const parseOrder = JSON.parse(orderLocalStorage);
                if (
                    parseOrder.hasOwnProperty('cartItems') &&
                    parseOrder.cartItems.length
                ) {
                    state.order.cartItems = parseOrder.cartItems;
                }
                if (parseOrder.hasOwnProperty('deliveryType')) {
                    state.order.deliveryType = parseOrder.deliveryType;
                }
                if (parseOrder.hasOwnProperty('paymentType')) {
                    state.order.paymentType = parseOrder.paymentType;
                }
                if (parseOrder.hasOwnProperty('message')) {
                    state.order.message = parseOrder.message;
                }
            }
        },
        addToCart: (state, payload) => {
            const item = _.find(state.order.cartItems, (item) => {
                if (payload.modification) {
                    return (
                        item.uuid === payload.uuid &&
                        item.modificationUuid === payload.modification.id
                    );
                } else {
                    return item.uuid === payload.uuid;
                }
            });
            if (!item) {
                let cartItem = {
                    uuid: payload.uuid,
                    name: payload.name,
                    price: payload.price / 100,
                    images: payload.images,
                    qty: 1
                };
                if (payload.modification) {
                    cartItem.modificationUuid = payload.modification.id;
                    cartItem.price = payload.modification.price / 100;
                    cartItem.name += ` (${payload.modification.name})`;
                }
                state.order.cartItems.push(cartItem);
            } else {
                item.qty += 1;
            }
            setOrderLocalStorage(state.order);
        },
        removeFromCart: (state, payload) => {
            const itemIndex = _.findIndex(
                state.order.cartItems,
                (item) => item.uuid === payload.uuid
            );
            if (itemIndex !== -1) {
                state.order.cartItems.splice(itemIndex, 1);
            }
            setOrderLocalStorage(state.order);
        },
        setDeliveryType: (state, payload) =>
            (state.order.deliveryType = payload),
        setPaymentType: (state, payload) => (state.order.paymentType = payload),
        setMessage: (state, payload) => (state.order.message = payload),
        setQty: (state, payload) => {
            let item;
            if (payload.modificationUuid) {
                item = _.find(
                    state.order.cartItems,
                    (item) =>
                        item.uuid === payload.uuid &&
                        item.modificationUuid === payload.modificationUuid
                );
            } else {
                item = _.find(
                    state.order.cartItems,
                    (item) => item.uuid === payload.uuid
                );
            }
            if (item) {
                if (payload.type === 'increment') {
                    item.qty += 1;
                } else if (payload.type === 'decrement' && item.qty > 1) {
                    item.qty -= 1;
                }
            }
            setOrderLocalStorage(state.order);
        }
    },
    getters: {
        isCartEnable: (state) => state.isCartEnable,
        isCartVisible: (state) => state.isCartVisible,
        cartState: (state) => state.cartState,
        cartItems: (state) => state.order.cartItems,
        deliveryType: (state) => state.order.deliveryType,
        paymentType: (state) => state.order.paymentType,
        message: (state) => state.order.message
    }
};
