export default {
    state: {
        customButtons: null
    },
    actions: {},
    mutations: {
        setCustomButtons(state, payload) {
            state.customButtons = payload;
        }
    },
    getters: {
        customButtons: (state) => state.customButtons
    }
};
