import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { ru } from './langs/ru';
import { ua } from './langs/ua';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'ua',
    silentTranslationWarn: true, //параметр, который выключает уведомления об остутствии переводов
    fallbackLocale: 'ua',
    messages: {
        ru,
        ua
    }
});

export default i18n;
