<template>
		<v-app class="app">
				<transition name="fade">
						<loader-component v-if="appIsLoading" />
						<template v-else>
								<error-alert-component v-if="errorState" :errorType="errorType" />
								<router-view v-else />
						</template>
				</transition>
		</v-app>
</template>

<script>
import Loader from "./components/loaders/CustomLoader";
import { getParams, sendMetric } from "./base";
import ErrorAlert from "./views/ErrorAlert";

export default {
		components: {
				"loader-component": Loader,
				"error-alert-component": ErrorAlert
		},
		data() {
				return {
						errorState: false,
						errorType: null
				};
		},
		computed: {
				appIsLoading() {
						return this.$store.getters.appIsLoading;
				},
				menu() {
						return this.$store.getters.menu;
				},
				callButtons() {
						return this.$store.getters.callButtons;
				},
				review() {
						return this.$store.getters.review;
				},
				social() {
						return this.$store.getters.social;
				},
				demo() {
						return this.$store.getters.demo;
				},
				placeName() {
						return this.$store.getters.placeName;
				},
				appType() {
						return this.$store.getters.appType;
				},
				singleMode() {
						let result = {
										active: false,
										activeModule: null
								},
								activeModules = [];
						const modules = Object.assign(
								{},
								{
										menu: this.$store.state.menu.menu,
										callButtons: this.$store.state.callButtons.callButtons,
										review: this.$store.state.review.review
								}
						);
						for (let key in modules) {
								if (modules.hasOwnProperty(key)) {
										if (modules[key] !== null) {
												if (key !== "menu") {
														activeModules.push(key);
												} else if (
														key === "menu" &&
														modules[key].type !== null
												) {
														activeModules.push(key);
												}
										}
								}
						}
						if (activeModules.length === 1) {
								result.active = true;
								result.activeModule = activeModules[0];
						}
						return result;
				}
		},
		methods: {
				runApp() {
						//пример разрешенного урла. Обязательно domain/qr/place_uuid
						//https://dev.yupa.app/qr/919cf9dd-2d9f-44d8-a4a5-cd398c0246e2
						const pathname = window.location.pathname,
								qrUrlRegexp = /\/?qr\/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}\/?/,
								qrUuidRegexp = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/,
								externalUrlRegexp = /\/?w\/\w{6}/;
						if (this.demo) {
								let placeUuid = pathname.match(qrUuidRegexp)[0];
								this.$store.commit("setPlaceUuid", placeUuid);
								this.$store
										.dispatch("getToken", {
												uuid: placeUuid,
												params: {
														type: this.appType,
														demo: 1
												}
										})
										.then(() => {
												this.getPlace().then(() => {
														this.setRoutePath(true);
												});
										})
										.catch((err) => {
												switch (err.response.status) {
														case 404:
																this.errorType = "invalid_qr";
																break;
														case 401:
																this.errorType = "token_expired";
																break;
														case 403:
																this.errorType = "forbidden";
																break;
														default:
																this.errorType = "service_not_working";
																break;
												}
												console.log(err);
												this.errorState = true;
												this.$store.commit("setAppIsLoading", false);
										});
								this.clearOrder();
						} else {
								if (
										qrUrlRegexp.test(pathname) ||
										externalUrlRegexp.test(pathname)
								) {
										if (qrUrlRegexp.test(pathname)) {
												let placeUuid = pathname.match(qrUuidRegexp)[0];
												this.$store.commit("setPlaceUuid", placeUuid);
												this.$store
														.dispatch("getToken", {
																uuid: placeUuid
														})
														.then(() => {
																this.getPlace().then(() => {
																		this.setRoutePath(true);
																});
														})
														.catch((err) => {
																switch (err.response.status) {
																		case 404:
																				this.errorType = "invalid_qr";
																				break;
																		case 401:
																				this.errorType = "token_expired";
																				break;
																		case 403:
																				this.errorType = "forbidden";
																				break;
																		default:
																				this.errorType = "service_not_working";
																				break;
																}
																console.log(err);
																this.errorState = true;
																this.$store.commit("setAppIsLoading", false);
														});
												this.clearOrder();
										} else {
												const hash = pathname
														.match(externalUrlRegexp)[0]
														.replace(/\/w\//, "");
												this.$store
														.dispatch("getToken", {
																uuid: hash
														})
														.then(() => {
																this.getPlace().then(() => {
																		this.setRoutePath(true);
																});
														})
														.catch((err) => {
																switch (err.response.status) {
																		case 404:
																				this.errorType = "invalid_web";
																				break;
																		case 401:
																				this.errorType = "token_web_expired";
																				break;
																		case 403:
																				this.errorType = "forbidden";
																				break;
																		case 400:
																				this.errorType =
																						"service_not_configure";
																				break;
																		default:
																				this.errorType = "service_not_working";
																				break;
																}
																console.log(err);
																this.errorState = true;
																this.$store.commit("setAppIsLoading", false);
														});
												this.clearOrder();
										}
								} else {
										const token = window.localStorage.getItem("token");
										if (token) {
												this.$store.commit("setToken", {
														token: token
												});
												this.getPlace().then(() => {
														this.setRoutePath();
												});
										} else {
												this.errorType = "invalid_qr";
												this.errorState = true;
												this.$store.commit("setAppIsLoading", false);
										}
								}
						}
				},
				getPlace() {
						return this.$store
								.dispatch("getPlace")
								.then(() => {
										this.setTitle();
										if (!this.demo) {
												sendMetric({ action: "SCAN" });
										}
										if (
												!this.menu.type &&
												!this.callButtons &&
												!this.review &&
												!this.social
										) {
												this.errorType = "service_not_configure";
												this.errorState = true;
												this.$store.commit("setAppIsLoading", false);
										} else {
												setTimeout(() => {
														this.$store.commit("setAppIsLoading", false);
														this.setGoBackButton();
												}, 100);
										}
								})
								.catch((err) => {
										switch (err.response.status) {
												case 404:
														this.errorType = "invalid_qr";
														break;
												case 401:
														this.errorType = "token_expired";
														break;
												default:
														this.errorType = "service_not_working";
														break;
										}
										console.log(err);
										this.errorState = true;
										this.$store.commit("setAppIsLoading", false);
								});
				},
				setRoutePath(homeRedirect = false) {
						let setRoute = (path) => {
								let currentRoutePath = this.$route.path;
								if (path !== currentRoutePath) {
										this.$router.push({
												path
										});
								}
						};
						if (this.singleMode.active) {
								switch (this.singleMode.activeModule) {
										case "menu":
												if (
														this.$store.state.menu.menu.type ===
														"INTERACTIVE" ||
														this.$store.state.menu.menu.type === "POSTER"
												) {
														setRoute("/menu/categories");
												} else {
														setRoute("/home");
												}
												break;
										case "callButtons":
												setRoute("/call-buttons");
												break;
										case "review":
												setRoute("/review");
												break;
										default:
												setRoute("/home");
												break;
								}
						}
						if (homeRedirect) {
								setRoute("/home");
						}
				},
				setGoBackButton() {
						if (!this.singleMode.active) {
								this.$store.commit("setAllowGoBackButton", true);
						} else if (
								this.singleMode.active &&
								(this.$store.state.menu.menu.type === "INTERACTIVE" ||
										this.$store.state.menu.menu.type === "POSTER")
						) {
								this.$store.commit("setAllowGoBackButton", true);
						}
				},
				setTitle() {
						if (this.placeName) {
								document.title += " | " + this.placeName;
						}
				},
				clearOrder() {
						this.$store.commit("clearOrder");
				},
				setDefaultLanguage() {
						const language = window.navigator.language;
						if (language) {
								const languageCode = language.split("-");
								switch (languageCode[0]) {
										case "uk":
												this.$store.commit("setLocale", "ua");
												break;
										case "ru":
												this.$store.commit("setLocale", "ru");
												break;
										default:
												this.$store.commit("setLocale", "ua");
												break;
								}
						} else {
								this.$store.commit("setLocale", "ua");
						}
				}
		},
		created() {
				this.setDefaultLanguage();
				const params = getParams();
				if (params.hasOwnProperty("demo")) {
						this.$store.commit("setDemo", true);
						document.body.classList.add("demo");
				}
				if (params.hasOwnProperty("type")) {
						this.$store.commit("setAppType", params.type);
				}
				this.runApp();
		}
};
</script>
