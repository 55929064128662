import Axios from 'axios';

export default {
    state: {
        review: null,
        reviewState: 'default',
        reviewPhotoBase64: null,
        reviewForSave: {
            rating: null,
            message: '',
            photo: null,
            audio: null,
            contact: null,
            name: null
        }
    },
    actions: {
        postReview(ctx, data) {
            return Axios.post('/api/application/review', data);
        },
        postReviewFile(ctx, data) {
            return Axios.post('/api/application/review_content_upload', data);
        }
    },
    mutations: {
        setReview(state, payload) {
            state.review = payload;
        },
        setReviewState(state, payload) {
            state.reviewState = payload;
        },
        setReviewProperty(state, payload) {
            state.reviewForSave[payload.property] = payload.value;
        },
        setReviewPhotoBase64(state, payload) {
            state.reviewPhotoBase64 = payload;
        },
        clearPhoto(state) {
            state.reviewForSave.photo = null;
            state.reviewPhotoBase64 = null;
        },
        restartState(state) {
            state.reviewForSave.rating = null;
            state.reviewForSave.message = '';
            state.reviewForSave.photo = null;
            state.reviewForSave.audio = null;
            state.reviewForSave.contact = null;
            state.reviewForSave.name = null;
            state.reviewPhotoBase64 = null;
        }
    },
    getters: {
        review: (state) => state.review,
        reviewState: (state) => state.reviewState
    }
};
