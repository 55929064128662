import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app';
import social from './modules/social';
import menu from './modules/menu';
import cart from './modules/cart';
import callButtons from './modules/callButtons';
import review from './modules/review';
import customButtons from './modules/customButtons';

Vue.use(Vuex);

export default new Vuex.Store({
    //выключить на проде!
    strict: true,
    modules: {
        app,
        social,
        menu,
        cart,
        callButtons,
        review,
        customButtons
    }
});
