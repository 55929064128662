export default {
    state: {
        social: null
    },
    actions: {},
    mutations: {
        setSocial(state, payload) {
            state.social = payload;
        }
    },
    getters: {
        social: (state) => state.social
    }
};
